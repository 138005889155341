<script>
export default {
  metaInfo: {
    title: "Test Group",
  }
};
</script>

<script setup>
import DynamicTestGroupView from "@/components/dynamic/TestGroupView.vue";
import PhpServiceView from "@/components/basic/php-wrappers/PhpServiceView.vue";
</script>

<template>
  <PhpServiceView
      :url="`?f=testing&f2=testsGroup&groupID=${$route.params.id}`"
   >
    <DynamicTestGroupView />
  </PhpServiceView>
</template>

<style scoped>

</style>
